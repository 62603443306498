import React, { FC, Children } from "react";
import classNames from "classnames";
import { Tooltip, Label } from "@qwilr/kaleidoscope";

interface IAvatarStack {
  limit?: number;
  useTooltips?: boolean;
}

const AvatarStack: FC<IAvatarStack> = ({ children, limit = 3, useTooltips = true }) => {
  const remaining = Children.count(children) - limit;
  const isOverflowed = remaining > 0;

  return (
    <div className={classNames("kl-avatar-stack", { "kl-avatar-stack--overflow": isOverflowed })}>
      {Children.map(children, (child, index) => {
        if (!child) return null;
        const { firstName, lastName } = (child as any).props;

        if (index < limit) {
          return (
            <div className="kl-avatar-stack__item">
              {useTooltips && (
                <Tooltip wrapTarget content={`${firstName} ${lastName}`}>
                  {child}
                </Tooltip>
              )}
              {!useTooltips && child}
            </div>
          );
        }
      })}
      {isOverflowed && (
        <Tooltip content={`And ${remaining} more`}>
          <div className="kl-avatar-stack__remaining">
            <Label element="span" size="s">
              {remaining}
            </Label>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default AvatarStack;
