import React, { FC, useEffect, useState } from "react";
import { Text, Label } from "@qwilr/kaleidoscope";

export interface ITOCItem {
  url: string;
  title: string;
}

interface IPostTocProps {
  contents: ITOCItem[];
}

const PostToc: FC<IPostTocProps> = ({ contents }) => {
  const [currentHeadingId, setCurrentHeadingId] = useState<string>();

  useEffect(() => {
    const headings = contents?.map((heading) => ({ ...heading, visible: false }));

    // Observe headings intersecting with the viewport
    const headingObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const currentId = `#${entry.target.id}`;
        const currentHeading = headings.find((heading) => heading.url === currentId);
        currentHeading.visible = entry.isIntersecting;
      });

      // Set the first visible heading as the current heading
      const visibleHeadings = headings.filter((heading) => heading.visible);
      setCurrentHeadingId(visibleHeadings[0]?.url);
    });

    contents?.forEach((heading) => {
      const element = document.querySelector(heading.url);
      headingObserver.observe(element);
    });

    return () => {
      headingObserver.disconnect();
    };
  }, [contents]);

  if (!contents) return null;

  return (
    <div className="kld-post-toc">
      <Label secondary className="kld-post-toc__label" id="toc-label" element="span">
        Contents
      </Label>
      <nav className="kld-post-toc__nav" aria-labelledby="toc-label">
        {contents?.map(({ url, title }) => (
          <Text size="s" key={url}>
            <a className="kld-post-toc__link" href={url} aria-current={currentHeadingId === url}>
              {title}
            </a>
          </Text>
        ))}
      </nav>
    </div>
  );
};

export default PostToc;
