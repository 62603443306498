import React, { FC, Fragment } from "react";
import { graphql, Link as GatsbyLink, PageProps } from "gatsby";
import { Helmet } from "react-helmet";
import { formatDistanceToNow } from "date-fns";
import PostToc, { ITOCItem } from "./PostToc";
import PostMarkdown from "./PostMarkdown";
import { useLocation } from "@reach/router";
import useWindowSize from "../../hooks/useWindowSize";
import {
  Avatar,
  Button,
  ButtonSize,
  ButtonType,
  CopyToClipboard,
  IconButton,
  Tooltip,
  TooltipSize,
  Heading,
  Label,
  Text,
} from "@qwilr/kaleidoscope";
import { ReactComponent as Figma } from "../../assets/figma.svg";
import { ReactComponent as Storybook } from "../../assets/storybook.svg";
import { ArrowLeft, ArrowRight, Edit, IconSize, Link } from "@qwilr/kaleidoscope/icons";
import { handleMouseUpByBlurring } from "@qwilr/kaleidoscope/utils";
import AvatarStack from "../../components/AvatarStack/AvatarStack";
import { PropsTable } from "../../components/PropsTable/PropsTable";
import classNames from "classnames";
import { Pill } from "../../components/Pill";

interface IPostLayoutProps extends PageProps {
  data: {
    mdx: {
      frontmatter: {
        title: string;
        description: string;
        figma: string;
        storybook: string;
        designer: string;
        engineer: string;
        contributors: string[];
        deprecated?: boolean;
      };
      body: string;
      tableOfContents: { items: ITOCItem[] };
      slug: string;
      parent: {
        mtime: string;
      };
    };
    allContributorsYaml: {
      nodes: any;
    };
  };
  pageContext: {
    prevPost: any;
    nextPost: any;
  };
}

const PostLayout: FC<IPostLayoutProps> = ({ data, pageContext }) => {
  const { prevPost, nextPost } = pageContext;
  const { frontmatter, body, tableOfContents, parent, slug } = data.mdx;
  const { title, description, figma, storybook, designer, engineer, contributors, deprecated } = frontmatter;
  const { width } = useWindowSize();
  const location = useLocation();

  const showToc = width > 900 && tableOfContents.items?.length > 0;

  const getContributor = (name: string) =>
    data.allContributorsYaml.nodes?.find((contributor: any) => contributor.name === name);

  const getNames = (fullName: string) => {
    const names = fullName.split(" ");
    return { firstName: names[0], lastName: names[names.length - 1] };
  };

  const pageDesigner = getContributor(designer);
  const pageEngineer = getContributor(engineer);
  const pageContributors = contributors?.map(getContributor);
  const hasAuthors = !!engineer || !!designer;
  const tocItems = [...(tableOfContents.items || [])];

  return (
    <div className={classNames("kld-post", { "kld-post--full": !showToc })}>
      <Helmet>
        <title>{`Kaleidoscope | ${title}`}</title>
        <meta name="description" content={description} />
      </Helmet>
      <article className={classNames("kld-post__page", { "kld-post__page--full": !showToc })}>
        <div className="kld-post__header">
          <Heading strong className="kld-post__title" level="2">
            {title}
            {deprecated && <Pill className="kld-post__pill">Deprecated</Pill>}
          </Heading>
          <div className="kld-post__meta">
            <div className="kld-post__links">
              {!!figma && <IconButton tooltip={{ content: "Figma" }} icon={<Figma />} href={() => figma} />}
              {!!storybook && (
                <IconButton tooltip={{ content: "Storybook" }} icon={<Storybook />} href={() => storybook} />
              )}
            </div>
            {hasAuthors && (
              <AvatarStack useTooltips={false}>
                {pageDesigner?.name === pageEngineer?.name && (
                  <Tooltip
                    wrapTarget
                    size={TooltipSize.Large}
                    content={
                      <Fragment>
                        <Label align="center" theme="dark" element="div">
                          Designer + Engineer
                        </Label>
                        <Text align="center" theme="dark" element="div">
                          {pageDesigner.name}
                        </Text>
                      </Fragment>
                    }
                  >
                    <Avatar {...getNames(pageDesigner.name)} image={pageDesigner.avatar} />
                  </Tooltip>
                )}
                {pageDesigner?.name !== pageEngineer?.name && !!pageDesigner && (
                  <Tooltip
                    wrapTarget
                    size={TooltipSize.Large}
                    content={
                      <Fragment>
                        <Label align="center" theme="dark" element="div">
                          Designer
                        </Label>
                        <Text align="center" theme="dark" element="div">
                          {pageDesigner.name}
                        </Text>
                      </Fragment>
                    }
                  >
                    <Avatar {...getNames(pageDesigner.name)} image={pageDesigner.avatar} />
                  </Tooltip>
                )}
                {pageDesigner?.name !== pageEngineer?.name && !!pageEngineer && (
                  <Tooltip
                    wrapTarget
                    size={TooltipSize.Large}
                    content={
                      <Fragment>
                        <Label align="center" theme="dark" element="div">
                          Engineer
                        </Label>
                        <Text align="center" theme="dark" element="div">
                          {pageEngineer.name}
                        </Text>
                      </Fragment>
                    }
                  >
                    <Avatar {...getNames(pageEngineer.name)} image={pageEngineer.avatar} />
                  </Tooltip>
                )}
              </AvatarStack>
            )}
          </div>
          <Text className="kld-post__description" element="p" size="xl">
            {description}
          </Text>
        </div>
        <PostMarkdown>{body}</PostMarkdown>
      </article>
      {showToc && <PostToc contents={tocItems} />}
      <footer className={classNames("kld-post__footer", { "kld-post__footer--full": !showToc })}>
        {!prevPost && (
          <GatsbyLink
            className="kld-post__footer-link kld-post__footer-link--prev"
            to="/"
            onMouseUp={handleMouseUpByBlurring}
          >
            <Text secondary size="s">
              Previous
            </Text>
            <Text className="kld-post__footer-link-title" size="xl">
              <ArrowLeft className="kld-post__footer-link-icon" />
              <Text size="xl">Kaleidoscope</Text>
            </Text>
          </GatsbyLink>
        )}
        {!!prevPost && (
          <GatsbyLink
            className="kld-post__footer-link kld-post__footer-link--prev"
            to={prevPost.url}
            onMouseUp={handleMouseUpByBlurring}
          >
            <Text secondary size="s">
              Previous
            </Text>
            <Text className="kld-post__footer-link-title" size="xl">
              <ArrowLeft className="kld-post__footer-link-icon" />
              <Text size="xl">{prevPost.title}</Text>
            </Text>
          </GatsbyLink>
        )}
        {!!nextPost && (
          <GatsbyLink
            className="kld-post__footer-link kld-post__footer-link--next"
            to={nextPost.url}
            onMouseUp={handleMouseUpByBlurring}
          >
            <Text secondary size="s">
              Next
            </Text>
            <Text className="kld-post__footer-link-title" size="xl">
              <Text size="xl">{nextPost.title}</Text>
              <ArrowRight className="kld-post__footer-link-icon" />
            </Text>
          </GatsbyLink>
        )}

        <div className="kld-post__footer-meta">
          <div className="kld-post__footer-contributors">
            {!!pageContributors?.length && (
              <AvatarStack>
                {pageContributors.map((contributor) => {
                  const names = contributor.name.split(" ");
                  return (
                    <Avatar
                      key={contributor.name}
                      firstName={names[0]}
                      lastName={names[names.length - 1]}
                      image={contributor.avatar}
                    />
                  );
                })}
              </AvatarStack>
            )}
            <Text secondary size="s">
              Last updated {formatDistanceToNow(new Date(parent.mtime))} ago
            </Text>
          </div>
          <div className="kld-post__footer-actions">
            <Button
              type={ButtonType.Tertiary}
              size={ButtonSize.Small}
              href={() => `https://github.com/qwilr/kaleidoscope-docs/tree/master/src/guides/${slug}.md`}
            >
              <Edit size={IconSize.Small} />
              Edit this page
            </Button>
            <Button
              type={ButtonType.Secondary}
              size={ButtonSize.Small}
              href={() =>
                "https://docs.google.com/forms/d/e/1FAIpQLSeid9Ha4YoZLDURcPj3YNFpdAQz4kRpx8BlHvT7XuSIXND-cg/viewform?usp=pp_url&entry.1633643218=" +
                "kaleidoscope.qwilr.com/" +
                slug
              }
            >
              📣 Submit feedback
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        figma
        storybook
        designer
        engineer
        contributors
        deprecated
      }
      slug
      body
      tableOfContents
      parent {
        ... on File {
          mtime
        }
      }
    }
    allContributorsYaml {
      nodes {
        role
        name
        avatar
      }
    }
  }
`;

export default PostLayout;
