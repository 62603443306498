import React, { Children, CSSProperties, FC } from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import {
  Button,
  ButtonSize,
  CopyToClipboard,
  IconButton,
  Heading,
  HeadingProps,
  Text,
  TextProps,
} from "@qwilr/kaleidoscope";
import { Link as LinkIcon } from "@qwilr/kaleidoscope/icons";
import Link from "../../components/Link/Link";
import PostCode from "./PostCode";
import IconsIndex from "../../components/IconsIndex/IconsIndex";
import { useLocation } from "@reach/router";
import { ColorTile } from "../../components/ColorTile/ColorTile";
import ColorTileGrid from "../../components/ColorTile/ColorTileGrid";
import { AssetLibrary } from "../../components/AssetLibrary/AssetLibrary";
import {
  DesignTokensPalette,
  DesignTokensColors,
  DesignTokensSpacing,
  DesignTokensBreakpoints,
  DesignTokensBorderRadii,
  DesignTokensShadows,
} from "../../components/DesignTokens/DesignTokens";

const MarkdownHeading: FC<HeadingProps> = ({ children, level, id, ...props }) => {
  const location = useLocation();
  const headingUrl = `${location.origin}${location.pathname}#${id}`;

  return (
    <Heading className="kld-post-markdown__heading" level={level} id={id} strong {...props}>
      <span className="kld-post-markdown__heading-inner">
        <span className="kld-post-markdown__heading-text">
          {children}
          <CopyToClipboard value={headingUrl} tooltip={{ content: "Copy link" }}>
            {({ onCopy }) => (
              <IconButton
                className="kld-post-markdown__heading-copy"
                icon={<LinkIcon />}
                size={ButtonSize.Small}
                onClick={onCopy}
              />
            )}
          </CopyToClipboard>
        </span>
      </span>
    </Heading>
  );
};

const MarkdownH1 = (props: Omit<HeadingProps, "level">) => <MarkdownHeading level="2" element="h1" {...props} />;

const MarkdownH2 = (props: Omit<HeadingProps, "level">) => <MarkdownHeading level="3" element="h2" {...props} />;

const MarkdownH3 = (props: Omit<HeadingProps, "level">) => <MarkdownHeading level="4" element="h3" {...props} />;

const MarkdownH4 = (props: Omit<HeadingProps, "level">) => <MarkdownHeading level="5" element="h4" {...props} />;

const MarkdownText = (props: TextProps) => <Text className="kld-post-markdown__text" element="p" size="l" {...props} />;

const MarkdownImage: FC = (props) => <img className="kld-post-markdown__image" {...props} />;

const MarkdownUl: FC = (props) => (
  <ul className="kld-post-markdown__list kld-post-markdown__list--unordered" {...props} />
);

const MarkdownOl: FC = (props) => (
  <ol className="kld-post-markdown__list kld-post-markdown__list--ordered" {...props} />
);

const MarkdownLi: FC = ({ children, ...props }) => (
  <li className="kld-post-markdown__list-item" {...props}>
    <Text size="l">{children}</Text>
  </li>
);

export const MarkdownInlineCode: FC = (props) => <code className="kld-post-markdown__inline-code" {...props} />;

const MarkdownTable: FC = (props) => <table className="kld-post-markdown__table" {...props} />;

const MarkdownTableHead: FC = (props) => <thead className="kld-post-markdown__table-head" {...props} />;

const MarkdownTableRow: FC = (props) => <tr className="kld-post-markdown__table-row" {...props} />;
const MarkdownTableHeadCell: FC = ({ children, ...props }) => (
  <th className="kld-post-markdown__table-head-cell" {...props}>
    <Text strong size="m">
      {children}
    </Text>
  </th>
);

const MarkdownTableCell: FC = ({ children, ...props }) => (
  <td className="kld-post-markdown__table-cell" {...props}>
    <Text size="s">{children}</Text>
  </td>
);

const MarkdownHr: FC = (props) => <hr className="kld-post-markdown__hr" {...props} />;

const MarkdownBlockquote: FC = (props) => <blockquote className="kld-post-markdown__blockquote" {...props} />;

interface MarkdownColumnsProps {
  columns?: number;
}

const MarkdownColumns: FC<MarkdownColumnsProps> = ({ children, columns }) => (
  <div
    className="kld-post-markdown__columns"
    style={{ "--columns": columns || `repeat(${Children.count(children).toString()}, 1fr)` } as CSSProperties}
  >
    {children}
  </div>
);

const MarkdownColumn: FC = ({ children }) => <div className="kld-post-markdown__column">{children}</div>;

interface IPostMarkdownProps {
  children: string;
}

const PostMarkdown: FC<IPostMarkdownProps> = ({ children }) => {
  return (
    <div className="kld-post-markdown">
      <MDXProvider
        components={{
          Button,
          h1: MarkdownH1,
          h2: MarkdownH2,
          h3: MarkdownH3,
          h4: MarkdownH4,
          p: MarkdownText,
          img: MarkdownImage,
          ul: MarkdownUl,
          ol: MarkdownOl,
          li: MarkdownLi,
          code: PostCode,
          inlineCode: MarkdownInlineCode,
          a: Link,
          table: MarkdownTable,
          thead: MarkdownTableHead,
          tr: MarkdownTableRow,
          th: MarkdownTableHeadCell,
          td: MarkdownTableCell,
          hr: MarkdownHr,
          blockquote: MarkdownBlockquote,
          IconsIndex,
          Columns: MarkdownColumns,
          Column: MarkdownColumn,
          ColorTileGrid,
          AssetLibrary,
          DesignTokensPalette,
          DesignTokensColors,
          DesignTokensSpacing,
          DesignTokensBreakpoints,
          DesignTokensBorderRadii,
          DesignTokensShadows,
        }}
      >
        <MDXRenderer>{children}</MDXRenderer>
      </MDXProvider>
    </div>
  );
};

export default PostMarkdown;
