import React from "react";
import { ColorTile } from "./ColorTile";

type Color = {
  lessToken: string;
  name: string;
  cssVariable: string;
  hex?: string;
};

interface ColorTileGrid {
  colors: Color[];
}

const ColorTileGrid = ({ colors }: ColorTileGrid) => (
  <div className="kld-color-tile-grid">
    {colors.map((c) => (
      <ColorTile key={c.cssVariable} {...c} />
    ))}
  </div>
);

export default () => (
  <ColorTileGrid
    colors={[
      {
        cssVariable: "--emerald",
        hex: "#00857b",
        lessToken: "@emerald",
        name: "Emerald",
      },
      {
        cssVariable: "--blue",
        hex: "#1099FC",
        lessToken: "@blue",
        name: "Blue",
      },
      {
        cssVariable: "--purple",
        hex: "#716BF1",
        lessToken: "@purple",
        name: "Purple",
      },
      {
        cssVariable: "--gold",
        hex: "#FFCE53",
        lessToken: "@gold",
        name: "Gold",
      },
      {
        cssVariable: "--orange",
        hex: "#FFB961",
        lessToken: "@orange",
        name: "Orange",
      },
      {
        cssVariable: "--red-light",
        hex: "#FF6161",
        lessToken: "@red-light",
        name: "Light red",
      },
      {
        cssVariable: "--red",
        hex: "#D71939",
        lessToken: "@red",
        name: "Red",
      },
      {
        cssVariable: "--slate",
        lessToken: "@slate",
        name: "Slate",
        hex: "#47535D",
      },
      {
        cssVariable: "--midnight",
        lessToken: "@midnight",
        name: "Midnight",
        hex: "#242C39",
      },
      {
        cssVariable: "--grey",
        lessToken: "@grey",
        name: "Grey",
        hex: "#81a2b2",
      },
      {
        cssVariable: "--grey25",
        lessToken: "@grey25",
        name: "Grey 25",
        hex: "#81a2b240",
      },
      {
        cssVariable: "--grey10",
        lessToken: "@grey10",
        name: "Grey 10",
        hex: "#81a2b21a",
      },
      {
        cssVariable: "--grey5",
        lessToken: "@grey5",
        name: "Grey 5",
        hex: "#81a2b20d",
      },
    ]}
  />
);
