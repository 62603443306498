import React, { ElementType, FC, useState } from "react";

import * as allIcons from "@qwilr/kaleidoscope/icons";
import { CopyToClipboard } from "@qwilr/kaleidoscope";
import { SearchInput, EmptyState, ButtonCard, Text } from "@qwilr/kaleidoscope";

// Filter these exports from the list of icons
const filterExports = ["IconSize"];

const IconsIndex: FC = () => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (value: string): void => {
    setSearchValue(value);
  };

  const filtered = Object.keys(allIcons).filter((key) => {
    return !searchValue ? true : key.toLowerCase().includes(searchValue.toLowerCase());
  });

  return (
    <div className="kld-icons-index">
      <div className="kld-icons-index__search">
        <SearchInput
          label="Search icons"
          placeholder="Search icons"
          onChange={handleSearchChange}
          onClear={(): void => setSearchValue("")}
          value={searchValue}
        />
      </div>
      {!!filtered.length && (
        <div className="kld-icons-index__grid">
          {filtered.map((key) => {
            if (filterExports.includes(key)) return null;
            const IconComponent = (allIcons as any)[key];
            return <IconTile key={key} component={IconComponent} label={key} />;
          })}
        </div>
      )}
      {!filtered.length && <EmptyState title="No icons found" subtitle="Try searching for another icon" />}
    </div>
  );
};

interface IIconTileProps {
  component: ElementType;
  label: string;
}

const IconTile: FC<IIconTileProps> = ({ component: Component, label }) => {
  const iconJSX = `<${label} />`;

  return (
    <CopyToClipboard value={iconJSX} tooltip={{ content: "Copy JSX" }}>
      {({ onCopy }) => (
        <ButtonCard className="kld-icons-index__card" onClick={onCopy}>
          <div className="kld-icons-index__icon">
            <Component />
          </div>
          <Text className="kld-icons-index__label" align="center" size="xs">
            {label}
          </Text>
        </ButtonCard>
      )}
    </CopyToClipboard>
  );
};

export default IconsIndex;
