import { ButtonCard, CopyToClipboard } from "@qwilr/kaleidoscope";
import React from "react";
import * as styles from "./ColorTile.css";

interface ColorTileProps {
  lessToken: string;
  name: string;
  cssVariable: string;
  hex?: string;
}

const ColorTile = ({ lessToken, name, hex, cssVariable }: ColorTileProps) => {
  return (
    <CopyToClipboard value={lessToken} tooltip={{ content: "Copy LESS token" }}>
      {({ onCopy }) => (
        <ButtonCard onClick={onCopy} className={styles.colorTile}>
          <div className="kld-color-tile__color" style={{ background: `var(${cssVariable})` }}></div>
          <div className="kld-color-tile__meta">
            <div className="kld-color-tile__name">{name}</div>
            <div>
              LESS: <b>{lessToken}</b>
            </div>
            {hex && (
              <div>
                Hex: <span className="kld-color-tile__hex">{hex}</span>
              </div>
            )}
          </div>
        </ButtonCard>
      )}
    </CopyToClipboard>
  );
};

export { ColorTile };
