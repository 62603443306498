import { PrismTheme } from "prism-react-renderer";

const colors = {
  char: "#d8dee9",
  comment: "#999999",
  keyword: "#c5a5c5",
  lineHighlight: "#14161a",
  primitive: "#5a9bcf",
  string: "#8dc891",
  variable: "#d7deea",
  boolean: "#ff8b50",
  punctuation: "#5fb3b3",
  tag: "#fc929e",
  function: "#79b6f2",
  className: "#fac863",
  method: "#6699cc",
  operator: "#fc929e",
};

const theme: PrismTheme = {
  plain: {
    backgroundColor: "transparent",
    color: "white",
  },
  styles: [
    {
      types: ["attr-name"],
      style: {
        color: colors.keyword,
      },
    },
    {
      types: ["comment", "block-comment", "prolog", "doctype", "cdata"],
      style: {
        color: colors.comment,
      },
    },
    {
      types: ["property", "number", "function-name", "constant", "symbol", "deleted"],
      style: {
        color: colors.primitive,
      },
    },
    {
      types: ["boolean"],
      style: {
        color: colors.boolean,
      },
    },
    {
      types: ["tag"],
      style: {
        color: colors.tag,
      },
    },
    {
      types: ["string"],
      style: {
        color: colors.string,
      },
    },
    {
      types: ["punctuation"],
      style: {
        color: colors.punctuation,
      },
    },
    {
      types: ["selector", "char", "builtin", "constant", "inserted"],
      style: {
        color: colors.char,
      },
    },
    {
      types: ["function"],
      style: {
        color: colors.function,
      },
    },
    {
      types: ["operator", "entity", "url", "url"],
      style: {
        color: colors.variable,
      },
    },
    {
      types: ["attr-value"],
      style: {
        color: colors.string,
      },
    },
    {
      types: ["keyword"],
      style: {
        color: colors.keyword,
      },
    },
    {
      types: ["atrule", "class-name"],
      style: {
        color: colors.className,
      },
    },
    {
      types: ["italic"],
      style: {
        fontStyle: "italic",
      },
    },
    {
      types: ["important", "bold"],
      style: {
        fontWeight: "bold",
      },
    },
  ],
};

export default theme;
