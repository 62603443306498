import React, { useEffect, useRef, useState } from "react";
import {
  ButtonCard,
  ButtonSize,
  CopyToClipboard,
  Heading,
  IconButton,
  SegmentedControl,
  Stack,
  Text,
} from "@qwilr/kaleidoscope";
import { palette, tokens, spacing, lightTheme, darkTheme, breakpoint } from "@qwilr/kaleidoscope/tokens";
import * as styles from "./DesignTokens.css";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { capitalize } from "lodash";
import { Copy } from "@qwilr/kaleidoscope/icons";
import { MarkdownInlineCode } from "../../layouts/Post/PostMarkdown";

function groupColors(colors: { [key: string]: string }) {
  const categories = new Set<string>();
  const result: { [key: string]: any } = {};

  for (const key of Object.keys(colors)) {
    categories.add(key.replace(/[0-9]/g, ""));
  }

  for (const category of Array.from(categories)) {
    const matches = Object.entries(colors).filter(([key]) => key.includes(category));
    result[category] = matches;
  }

  return result;
}

const PaletteTile = ({ color, label, prefix = "" }: { color: string; label: string; prefix?: string }) => {
  return (
    <Stack className={styles.tile}>
      <CopyToClipboard value={[prefix, label].filter(Boolean).join(".")} tooltip={{ content: "Copy token" }}>
        {({ onCopy }) => (
          <ButtonCard onClick={onCopy}>
            <div className={styles.swatch} style={assignInlineVars({ [styles.swatchColor]: color })} />
          </ButtonCard>
        )}
      </CopyToClipboard>
      <Text secondary size="s">
        {label.replace(/\D/g, "")}
      </Text>
    </Stack>
  );
};

const ColorRow = ({
  color,
  label,
  prefix = "",
  theme,
}: {
  color: string;
  label: string;
  prefix?: string;
  theme: string;
}) => {
  const value = [prefix, label].filter(Boolean).join(".");
  const swatchRef = useRef<HTMLDivElement>(null);
  const [colorValue, setColorValue] = useState("");

  useEffect(() => {
    if (!swatchRef.current) return;
    const customProp = color.replace("var(", "").replace(")", "");
    const computedColor = getComputedStyle(swatchRef.current).getPropertyValue(customProp);
    setColorValue(computedColor);
  }, [theme]);

  return (
    <Stack
      className={styles.row}
      direction="horizontal"
      align="center"
      paddingTop="xs"
      paddingBottom="xs"
      justify="spaceBetween"
      gap="m"
    >
      <Stack direction="horizontal" align="center" gap="s" style={{ flex: "1 1 30%" }}>
        <div
          ref={swatchRef}
          className={[styles.color, theme].join(" ")}
          style={assignInlineVars({ [styles.swatchColor]: color })}
        />
        <Text size="s">
          <MarkdownInlineCode>{value}</MarkdownInlineCode>
        </Text>
      </Stack>
      <div style={{ flex: "1 1 20%" }}>
        <Text secondary size="s">
          {colorValue}
        </Text>
      </div>
      <CopyToClipboard value={value} tooltip={{ content: "Copy token" }}>
        {({ onCopy }) => (
          <IconButton className={styles.rowCopy} size={ButtonSize.Small} icon={<Copy />} onClick={onCopy} />
        )}
      </CopyToClipboard>
    </Stack>
  );
};

export const DesignTokensPalette = () => {
  const groups = groupColors(palette);

  return (
    <Stack paddingTop="xxxl" paddingBottom="xxxl" gap="xxl">
      {Object.entries(groups).map(([group, colors]) => (
        <Stack key={group} gap="s">
          <Heading level="5">{capitalize(group)}</Heading>
          <Stack wrap direction="horizontal" gap="s">
            {colors.map(([label, color]) => (
              <PaletteTile key={label} color={color} label={label} prefix="palette" />
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export const DesignTokensColors = ({ filter }: { filter?: string }) => {
  const [theme, setTheme] = useState(lightTheme);

  const colorTokens = filter
    ? Object.entries(tokens.color).filter(([key]) => key.includes(filter))
    : Object.entries(tokens.color);

  return (
    <Stack paddingTop="l" paddingBottom="xxxl" gap="l">
      <SegmentedControl
        labelHidden
        label="Theme"
        selectedValue={theme}
        onClickHandler={setTheme}
        options={[
          { label: "Light theme", value: lightTheme },
          { label: "Dark theme", value: darkTheme },
        ]}
      />
      <Stack>
        {colorTokens.map(([label, color]) => (
          <ColorRow key={label} color={color} label={label} prefix="tokens.color" theme={theme} />
        ))}
      </Stack>
    </Stack>
  );
};

const DesignTokensTableRow = ({ prefix, name, value }: { prefix?: string; name: string; value: string }) => {
  const [rawValue, setRawValue] = useState(value.includes("var(--") ? "" : value);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rawValue) return;

    const tempElement = document.createElement("div");
    const customProp = value.replace("var(", "").replace(")", "");
    wrapperRef.current?.appendChild(tempElement);
    tempElement.style.all = value;
    const computed = getComputedStyle(tempElement).getPropertyValue(customProp);
    tempElement.remove();
    setRawValue(computed);
  }, []);

  return (
    <Stack
      ref={wrapperRef}
      className={styles.row}
      direction="horizontal"
      align="center"
      paddingTop="xs"
      paddingBottom="xs"
      gap="m"
    >
      <div style={{ flex: "1 1 50%" }}>
        <Text size="s">
          <MarkdownInlineCode>{`${prefix}${name}`}</MarkdownInlineCode>
        </Text>
      </div>
      <div style={{ flex: "1 1 50%" }}>
        <Text secondary size="s">
          {rawValue}
        </Text>
      </div>
      <CopyToClipboard value={`${prefix}${name}`} tooltip={{ content: "Copy token" }}>
        {({ onCopy }) => (
          <IconButton className={styles.rowCopy} size={ButtonSize.Small} icon={<Copy />} onClick={onCopy} />
        )}
      </CopyToClipboard>
    </Stack>
  );
};

export const DesignTokensTable = ({
  prefix = "",
  unit = "",
  tokens,
  filter = "",
}: {
  prefix?: string;
  unit?: string;
  tokens: { [key: string]: string };
  filter?: string;
}) => {
  const filteredTokens = filter
    ? Object.entries(tokens).filter(([key]) => key.includes(filter))
    : Object.entries(tokens);

  return (
    <Stack paddingBottom="xxxl">
      <Stack className={styles.row} direction="horizontal" align="center" paddingTop="xs" paddingBottom="xs" gap="m">
        <div style={{ flex: "1 1 50%" }}>
          <Text strong size="s">
            Token
          </Text>
        </div>
        <div style={{ flex: "1 1 50%" }}>
          <Text strong size="s">
            {`Value${unit ? ` (${unit})` : ""}`}
          </Text>
        </div>
        <div style={{ width: 32, height: 32, flex: "0 0 auto" }} />
      </Stack>
      {filteredTokens.map(([key, value]) => (
        <DesignTokensTableRow name={key} key={key} prefix={prefix} value={value} />
      ))}
    </Stack>
  );
};

export const DesignTokensSpacing = () => {
  return <DesignTokensTable tokens={tokens.spacing} unit="px" prefix="tokens.spacing." />;
};

export const DesignTokensBreakpoints = () => {
  return <DesignTokensTable tokens={breakpoint} prefix="breakpoint." />;
};

export const DesignTokensBorderRadii = ({ filter }) => {
  return <DesignTokensTable tokens={tokens.borderRadius} prefix="tokens.borderRadius." filter={filter} />;
};

export const DesignTokensShadows = () => {
  return <DesignTokensTable tokens={tokens.shadow} prefix="tokens.shadow." />;
};
